var config_settings = {
	"prod":{
		server_path: 'https://productionbackend.infurnia.com/',
		analytics_path: 'https://analytics.infurnia.com/',
		auth_redirect_path: 'https://login.infurnia.com/?target=csp',
		static_file_server_path: 'https://productionbackend.infurnia.com/',
		onboarding_path: 'https://sales.infurnia.com/',
		sentry_environment: 'production',
		static_path: 'https://staticassets.infurnia.com',
		ticketing_bucket_name:'infurnia-production-ticketing',
        csp_path: 'https://csp.infurnia.com'
	},
	"preprod":{
		server_path: 'https://preprodbackend.infurnia.com/', 
		analytics_path: 'https://preprodanalytics.infurnia.com/',
		auth_redirect_path: 'https://preprodlogin.infurnia.com/?target=csp',
		static_file_server_path: 'https://preprodprodbackend.infurnia.com/',
		onboarding_path: 'https://preprodsales.infurnia.com/',
		sentry_environment: 'preprod',
		static_path: 'https://preprodstaticassets.infurnia.com',
		ticketing_bucket_name:'infurnia-preprod-ticketing',
        csp_path: 'https://preprodcsp.infurnia.com'
	},
	"stage":{
		server_path: 'https://stagebackend.infurnia.com/', 
		analytics_path: 'https://preprodanalytics.infurnia.com/',
		auth_redirect_path: 'https://stagelogin.infurnia.com/?target=csp',
		// static_file_server_path: 'https://stagebackend.infurnia.com/',
		// auth_redirect_path: 'https://stagedesign.infurnia.com/login/admin',
		// website_url: 'https://stagedesign.infurnia.com/',
		segment_id: 'A5KwgtfABcvlFWLeX4xD3hdozvyFtQSF',
		onboarding_path: 'https://stagesales.infurnia.com/',
		sentry_environment: 'stage',
		static_path: 'https://stagestaticassets.infurnia.com',
		ticketing_bucket_name:'infurnia-stage-vitess-ticketing',
        csp_path: 'https://stagecsp.infurnia.com'
	},
	"dev":{
		server_path: 'https://devbackend.infurnia.com/',
		analytics_path: 'https://stagebackend5.infurnia.com/',
		auth_redirect_path: 'https://stagelogin.infurnia.com/?target=csp',
		segment_id: 'A5KwgtfABcvlFWLeX4xD3hdozvyFtQSF',
		onboarding_path: 'https://sales.infurnia.com/',
		static_path: 'https://stagestaticassets.infurnia.com',
		ticketing_bucket_name:'infurnia-stage-vitess-ticketing',
        csp_path: 'https://localcsp.infurnia.com'
	}
}

var config = config_settings[process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV :'stage']
global.config = config
