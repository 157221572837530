import React, { useCallback, useEffect, useState } from "react";
import { Switch, Drawer, Tabs, Upload, Badge, Button as AntdButton, Tooltip, Input as AntdInput, Rate, DatePicker, Checkbox } from 'antd';
import { Button, Input, Row, Col } from "reactstrap";
import Select from 'react-select';
import { FireFilled, InboxOutlined, PaperClipOutlined, DeleteOutlined, PlusOutlined, MinusCircleOutlined, PlusCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { toastr } from "react-redux-toastr";
import general_fetch from "../../utils/fetch";
import { colourStyles4 } from "../../utils/react_select_styles";
import { date_string } from "../../utils/date_string";
import { convert_to_readable } from "../../utils/convert_to_readable";
import timeSince from "../../utils/timeSince";
import { user_details_fetched } from "../../actions";
import { customer_tickets_status_options } from "../../utils/customer_tickets_status_options";
import { bo_tickets_status_options } from "../../utils/bo_tickets_status_options";
import { confirmAlert } from "react-confirm-alert";
import DeleteNotification from "../../components/DeleteNotification";
import NA from "../../components/NA";
import { EditBackOffficeTicket } from "../BackOfficeTickets/EditBackOffficeTicket";
import {impact_area_options} from "../../utils/cs_ticket_impact_area";
import dayjs from 'dayjs';
import {ticketChecklist} from "../../utils/ticket_checklist";

const { Dragger } = Upload;

const parse_org_info = (org_info) => {
    try {
        let org_ids = JSON.parse(org_info).map(x => x.id);
        return org_ids;
    } catch(err) {
        return [];
    }
}

const disabledDate = (current) => {
  return current && dayjs(current).day() != 1;
};

const order_org_id_options = (values) => {
  return values.filter((v) => !v.editable).concat(values.filter((v) => v.editable));
};

const org_id_option_style = {
    multiValue: (base, state) => {
        return state.data.editable ? base : { ...base, backgroundColor: 'gray' };
    },
    multiValueLabel: (base, state) => {
        return state.data.editable
            ? base
            : { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 };
    },
    multiValueRemove: (base, state) => {
        return state.data.editable ? base : { ...base, display: 'none' };
    },
}

const submit = (yes_del, no_del, message, button_name, Custom_modal) => {
	confirmAlert({
	  title: 'Confirm to submit',
	  message: message,
	  customUI: ({ onClose }) => {
		return (
		  Custom_modal ?
			<div>
			  <Custom_modal yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
			:
			<div className='custom-ui'>
			  <DeleteNotification yes_del={yes_del} no_del={no_del} onClose={onClose} text={message} button_name={button_name} />
			</div>
		);
	  }
	});
}

const check_all_org_setting_access = ({user_details, team_info}) => {
    if(user_details && user_details.role && user_details.role.store && user_details.role.store.includes('customer_success_admin')) {
        return true
    }
    // product manager can edit any ticket
    if(user_details && user_details.role && user_details.role.store && user_details.role.store.includes('infurnia_product_manager')) {
        return true
    }
    // all team leads can edit any ticket
    if(team_info && user_details && user_details.id) {
        for(let user_id in team_info) {
            if(team_info[user_id].team_lead == user_details.id) {
                return true;
            }
        }
    }
    return false;
}

const check_ticket_edit_access = ({user_details, selected_ticket, team_info}) => {
    // customer success admin can edit any ticket
    if(user_details && user_details.role && user_details.role.store && user_details.role.store.includes('customer_success_admin')) {
        return true
    }
    // product manager can edit any ticket
    if(user_details && user_details.role && user_details.role.store && user_details.role.store.includes('infurnia_product_manager')) {
        return true
    }
    // all team leads can edit any ticket
    if(team_info && user_details && user_details.id) {
        for(let user_id in team_info) {
            if(team_info[user_id].team_lead == user_details.id) {
                return true;
            }
        }
    }

    // team lead of the creator's team can edit that ticket
    /* if(team_info && selected_ticket && selected_ticket.creator_id && user_details && user_details.id) {
        let this_team = team_info[selected_ticket.creator_id]
        if(this_team) {
            if(this_team.team_lead == user_details.id) {
                return true
            }
        }
    } */
    // the creator of the ticket can edit the ticket
    if(selected_ticket && selected_ticket.creator_id && user_details && user_details.id && (user_details.id == selected_ticket.creator_id)) {
        return true
    }
    return false
}

const jira_project_options = [
    {value: 'PT', label: 'Product Team'},
    {value: 'AP', label: 'Applications'},
    {value: 'CT', label: 'Core Tech'},
    {value: 'GK', label: 'Geometric Kernel'},
    {value: 'GR', label: 'Graphics'},
    {value: 'ST', label: 'Server Tech'},
    {value: 'CSP', label: 'Customer Success Portal'},
    {value: 'MES', label: 'MES'},
    {value: 'AD', label: 'Admin Portal'},
];

const jira_type_options = [
    {label: "Epic", value: "Epic"},
    {label: "Task", value: "Task"},
    {label: "Bug", value: "Bug"},
    {label: "QA Bug", value: "QA Bug"},
];

const jira_assignee_options = [
    {label: "Nikhil Kumar", value: "557058:6bdffd8b-34b3-41be-9c6a-6a303ade9b17"},
    {label: "Koushik Challa", value: "557058:befb16fb-2151-4a28-a34d-aa5596714d41"},
    {label: "Akash Dubey", value: "557058:f2cff7a8-b59e-4c12-bfff-6176dd4e4e18"},
    {label: "Akshat Jain", value: "603de5691c44230072ef894d"},
    {label: "Shamit Monga", value: "604b22adb020eb0068683772"},
    {label: "Bhargav Siva Phaneendra Annavarapu", value: "62bd820a268cac6e31c53572"},
    {label: "Dishank Goel", value: "62bd820c118b20bee2bca5d1"},
    {label: "Sai Krishna", value: "62bd820efa171a27239e370f"},
    {label: "lokesh.kumar", value: "62d535d3d1bb05497b0139bf"},
    {label: "Atharva Varde", value: "712020:2d094dbd-32f2-438b-8df1-257d4817555f"},
    {label: "Parth Ajmera", value: "712020:84e9b1ff-2767-4b03-881f-93ef78021667"},
    {label: "Vedant Saboo", value: "712020:781c7956-ad9c-4007-85bd-9bda51ea7eca"},
    {label: "Vinayak Bharadwaj", value: "712020:2961bded-bd18-4f9e-9e39-5a582ea98e44"},
    {label: "Yash Basrani", value: "712020:df35cec5-d9c0-4605-8871-f85dd12c08ac"},
];

const EditCustomerTicket = ({ open, toggle, all_orgs, current_org, selected_ticket_id, fetch_customer_tickets, set_page_loader, set_view_mode_true, cs_org_users, user_details, team_info }) => {

	const [title, set_title] = useState();
    const [impact_area, set_impact_area] = useState();
	const [type, set_type] = useState();
	// const [org_id, set_org_id] = useState();
    const [org_ids, set_org_ids] = useState([]);
    const [org_ids_options, set_org_ids_options] = useState([]);
	const [priority, set_priority] = useState();
	const [expected_resolution_time, set_expected_resolution_time] = useState();
	const [assignee_id, set_assignee_id] = useState();
    const [creator_id, set_creator_id] = useState();
	// const [customer_priority, set_customer_priority] = useState();
	const [client_notification, set_client_notification] = useState(true);
	const [auto_close, set_auto_close] = useState(true);
	const [description, set_description] = useState();
	const [design_link, set_design_link] = useState();
	const [status, set_status] = useState();
	const [existing_files, set_existing_files] = useState();
	const [new_files, set_new_files] = useState();
	const [new_drive_links, set_new_drive_links] = useState(['']);
	// const [deleted_file_ids, set_deleted_file_ids] = useState();
	const [new_comment, set_new_comment] = useState('');
	const [bo_ticket_comments, set_bo_ticket_comments] = useState([]);
	const [active_key, set_active_key] = useState("1");
	const [selected_ticket, set_selected_ticket] = useState();
	const [view_mode, set_view_mode] = useState(true);
	const [show_back_office_ticket_drawer, set_show_back_office_ticket_drawer] = useState(false);
	const [selected_back_office_ticket, set_selected_back_office_ticket] = useState(null);

    const [jira_assignee, set_jira_assignee] = useState();
    const [jira_type, set_jira_type] = useState();
    const [jira_project, set_jira_project] = useState();
	const [jira_summary, set_jira_summary] = useState();
	const [jira_description, set_jira_description] = useState();
	const [show_jira_creation_flow, set_show_jira_creation_flow] = useState(false);
	const [show_jira_linking_flow, set_show_jira_linking_flow] = useState(false);
	const [jira_details, set_jira_details] = useState();
	const [jira_key, set_jira_key] = useState();
    const [checklist, set_checklist] = useState({});

	const update_status = async(customer_ticket_id, status) => {
		try{
			let resp = await general_fetch({url:'customer_ticket/update', body:{customer_ticket_id, status}})
			fetch_customer_tickets()
			toastr.success('Successfully updated ticket status')
		}catch(err){
			toastr.error(`Coudln't update ticket status`)
			console.error(err)
		}
	}

    const follow_ticket = async ({customer_ticket_id}) => {
		try{
			let resp = await general_fetch({url:'customer_ticket/follow_ticket', body:{customer_ticket_id, follower_id: user_details.id}});
			toastr.success('Successfully followed ticket')
			fetch_customer_tickets()
		}catch(err){
			console.error(err)
			toastr.error(`Couldn't follow ticket`)
		}
    }

	const update_ticket_details = async () => {
		try {
			let fd = new FormData();
			if (title != selected_ticket.title) fd.append('title', title);
            if (impact_area != selected_ticket.impact_area) fd.append('impact_area', impact_area);
			// if (org_id != selected_ticket.org_id) fd.append('org_id', org_id);
            if(org_ids) {
                fd.append('org_info', JSON.stringify(org_ids.map(x => {return {id: x.value}})))
            }
			if (priority != selected_ticket.priority) fd.append('priority', priority);
			if (type != selected_ticket.type) fd.append('type', type)
			if (expected_resolution_time != selected_ticket.expected_resolution_time) fd.append('expected_resolution_time', expected_resolution_time)
			if (assignee_id != selected_ticket.assignee_id) fd.append('product_manager_id', assignee_id)
			if (creator_id != selected_ticket.creator_id) fd.append('creator_id', creator_id)
			// if (customer_priority != selected_ticket.customer_priority) fd.append('customer_priority', customer_priority);
			if (description != selected_ticket.description) fd.append('description', description);
			if ((client_notification && !selected_ticket.client_notification) || (!client_notification && selected_ticket.client_notification)) fd.append('client_notification', client_notification)
			if ((auto_close && !selected_ticket.auto_close) || (!auto_close && selected_ticket.auto_close)) fd.append('auto_close', auto_close)
			if (design_link && design_link.split('/') && design_link.split('/').length > 4 && design_link.split('/')[4] != selected_ticket.design_branch_id) {
				fd.append('design_branch_id', design_link.split('/')[4])
			}
			if (status != selected_ticket.status) fd.append('status', status)

			fd.append('customer_ticket_id', selected_ticket.id)

			set_page_loader(true, 'Please wait...')
            let resp = await general_fetch({ url: 'customer_ticket/update', body: fd, is_form_data: true });
			set_page_loader(false)
			fetch_customer_tickets()
			onclick_handleclose()
			toastr.success('Successfully updated Ticket')

		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't update ticket details`)
		}
	}

	const add_ticket_files = async () => {
		try {
			let fd = new FormData();

			if (new_files && new_files.length) {
				for (let file of new_files) {
					fd.append('files', file.originFileObj)
				}
			}
			fd.append('customer_ticket_id', selected_ticket.id)
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'customer_ticket/update', body: fd, is_form_data: true })
			set_page_loader(false)
			get_customer_ticket_by_id(selected_ticket_id)
			set_new_files([])
			toastr.success('Successfully added Files')

		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't add Files`)
		}
	}

	const add_drive_links = async () => {
		try {

			let body = {}

			if(new_drive_links && new_drive_links.length){
				let temp_drive_links = new_drive_links.filter(o => o.trim())
				if(temp_drive_links && temp_drive_links.length){
					body['drive_links'] = new_drive_links
				}else{
					toastr.error('Link cannot be empty')
					return
				}
			}
			body['customer_ticket_id'] = selected_ticket_id
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ url: 'customer_ticket/update', body })
			set_page_loader(false)
			get_customer_ticket_by_id(selected_ticket_id)
			set_new_drive_links([''])
			toastr.success('Successfully added Links')

		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't add Links`)
		}
	}

	const get_comments = async (customer_ticket_id) => {
		try {
			let resp = await general_fetch({ 'url': 'customer_ticket/get_comments', body: { customer_ticket_id: customer_ticket_id ? customer_ticket_id : selected_ticket.id } })
			set_bo_ticket_comments(resp)
		} catch (err) {
			console.error(err)
			toastr.error(`Couldn't get comments`)
		}
	}

	const add_comment = async () => {
		try {
			let resp = await general_fetch({ 'url': 'customer_ticket/add_comment', body: { customer_ticket_id: selected_ticket.id, comment: new_comment } })
			get_comments()
			set_new_comment('')
		} catch (err) {
			console.error(err)
			toastr.error(`Couldn't add comment`)
		}
	}

	const create_jira = async () => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({ 'url': 'customer_ticket/create_jira_ticket', body: { customer_ticket_id: selected_ticket.id, summary: jira_summary, description: jira_description, project: jira_project.value, type: jira_type.value, assignee: jira_assignee.value } })
			set_page_loader(false)
			fetch_customer_tickets()
			set_show_jira_creation_flow(false)
			set_show_jira_linking_flow(false)
            set_jira_assignee('');
            set_jira_type('')
            set_jira_project('')
			set_jira_summary('')
			set_jira_description('')
			get_customer_ticket_by_id(selected_ticket_id)
			let jira_details = await get_jira_details()
			toastr.success(`Successfully created Jira issue`)
            let ticket_status = 'jira_added';
            if(jira_details) {
                if(jira_details.status == 'Done') {
                    ticket_status = 'jira_done';
                } else if(['In Progress', 'Dev', 'Stage', 'Preprod'].includes(jira_details.status)) {
                    ticket_status = 'jira_in_progress';
                }
            }
            update_status(selected_ticket.id, ticket_status)
            get_customer_ticket_by_id(selected_ticket.id)
		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't create Jira issue`)
		}
	}

	const link_existing_jira = async () => {
		try {
			if(!jira_key || jira_key.includes('infurniacoretech.atlassian.net')){
				toastr.error(`Please provide a jira issue key`)
				return
			}

			let resp = await general_fetch({url:'customer_ticket/link_existing_jira', body:{customer_ticket_id: selected_ticket.id, jira_key: jira_key.toUpperCase()}})
			fetch_customer_tickets()
			set_jira_key('')
			set_show_jira_creation_flow(false)
			set_show_jira_linking_flow(false)
			set_jira_details('')
            let jira_details = await get_jira_details();
			toastr.success(`Successfully linked Jira issue`)
            let ticket_status = 'jira_added';
            if(jira_details) {
                if(jira_details.status == 'Done') {
                    ticket_status = 'jira_done';
                } else if(['In Progress', 'Dev', 'Stage', 'Preprod'].includes(jira_details.status)) {
                    ticket_status = 'jira_in_progress';
                }
            }
            update_status(selected_ticket.id, ticket_status)
            get_customer_ticket_by_id(selected_ticket.id)
		} catch (err) {
			set_page_loader(false)
			console.error(err)
			toastr.error(`Couldn't link Jira issue`)
		}
	}

	const get_jira_details = async (customer_ticket_id) => {
		try {
			let resp = await general_fetch({ 'url': 'customer_ticket/get_jira_details', body: { customer_ticket_id: customer_ticket_id ? customer_ticket_id : selected_ticket.id } })
			if(resp){
				set_jira_details(resp)
			}
            return resp;
		} catch (err) {
			console.error(err)
		}
	}

	const get_assignee_list = () => {
		if(cs_org_users && cs_org_users.length){
			let temp_cs_org_users = JSON.parse(JSON.stringify(cs_org_users))
            temp_cs_org_users = temp_cs_org_users.filter(o => o.role.includes('infurnia_product_manager'))
			return temp_cs_org_users.map(o => { return { value: o.id, label: o.first_name } }) 
		}
		return []
	}

	const delink_jira_issue = async() => {
		try {
			let resp = await general_fetch({'url':'customer_ticket/delink_jira_issue',body:{customer_ticket_id: selected_ticket_id}})
			fetch_customer_tickets()
			set_jira_key('')
			set_show_jira_creation_flow(false)
			set_show_jira_linking_flow(false)
			get_customer_ticket_by_id(selected_ticket_id)
			toastr.success(`Successfully delinked Jira issue`)
		}catch (err) {
			console.error(err)
			toastr.error(`Couldn't delinked Jira issue`)
		}
	}

	const get_customer_ticket_by_id = async (customer_ticket_id) => {
		try {
			let resp = await general_fetch({'url':'customer_ticket/get_by_id',body:{customer_ticket_id}})
			set_selected_ticket(resp)
            return resp;
		}catch (err) {
			console.error(err)
		}
	}

	const delete_file = async (file_id) => {
		try {
			set_page_loader(true, 'Please wait...')
			let resp = await general_fetch({'url':'customer_ticket/delete_file',body:{customer_ticket_id: selected_ticket_id, file_id}})
			set_page_loader(false)
			get_customer_ticket_by_id(selected_ticket_id)
		}catch (err) {
			console.error(err)
		}
	}

    const update_jira_due_date = async (jira_id, due_date) => {
		try {
			let resp = await general_fetch({'url':'customer_ticket/update_jira_due_date', body:{jira_id, due_date}})
            get_jira_details()
			toastr.success(`Successfully updated jira due date`)
		}catch (err) {
			console.error(err)
			toastr.error(`Couldn't update Jira due date`)
		}
    }

	const select_back_office_ticket = back_office_ticket_id => {
		set_selected_back_office_ticket(back_office_ticket_id)
		set_show_back_office_ticket_drawer(true)
	}

    const get_jira_link = (jira_key) => {
        return `https://infurniacoretech.atlassian.net/browse/${jira_key}`;
    }

    const update_checklist_status = async (key) => {
        let c = JSON.parse(JSON.stringify(checklist))
        c[key] = c[key] ? false : true;
        try {
			let resp = await general_fetch({url:'customer_ticket/update', body:{customer_ticket_id: selected_ticket.id, checklist: JSON.stringify(c)}})
            set_checklist((prev) => {
                let new_checklist = JSON.parse(JSON.stringify(prev))
                new_checklist[key] = new_checklist[key] ? false : true;
                return new_checklist
            })
            console.log(key)
        } catch(err) {
            console.log(err)
            toastr.error('Could not update checklist status')
        }
    }

	const onclick_handleclose = () => {
		toggle()
		set_title('')
        set_impact_area()
		set_type('')
		// set_org_id('')
        set_org_ids([])
        set_org_ids_options([])
		set_priority('')
		set_assignee_id('') 
        set_creator_id('')
		// set_customer_priority('')
		set_client_notification(true)
		set_auto_close(true)
		set_description('')
		set_design_link('')
		set_status('')
		set_new_files([])
		set_existing_files([])
		// set_deleted_file_ids([])
		set_bo_ticket_comments([])
		set_active_key("1")
		set_show_jira_creation_flow(false)
		set_show_jira_linking_flow(false)
        set_jira_assignee('')
        set_jira_type('')
        set_jira_project('')
		set_jira_summary('')
		set_jira_description('')
		set_jira_details('')
        set_checklist({})
		set_view_mode(false)
	}

	useEffect(() => {
		if (selected_ticket) {
			set_title(selected_ticket.title);
            set_checklist(JSON.parse(selected_ticket.checklist))
            set_impact_area(selected_ticket.impact_area);
			set_type(selected_ticket.type);
            if(selected_ticket.type == 'feature') {
                set_jira_type(jira_type_options[1])
            } else if(selected_ticket.type == 'bug') {
                set_jira_type(jira_type_options[2])
            }
			set_expected_resolution_time(selected_ticket.expected_resolution_time)
			set_assignee_id(selected_ticket.product_manager_id);
            set_creator_id(selected_ticket.creator_id);
            if(all_orgs && all_orgs.length > 0) {
                let org_map = {};
                for(let org of all_orgs) { org_map[org.id] = org; }
                let parsed_org_ids = parse_org_info(selected_ticket.org_info);
                let current_org_ids = [], this_org_ids_options = [], option_map = {};
                for(let org_id of parsed_org_ids) {
                    let org = org_map[org_id];
                    if(org) {
                        this_org_ids_options.push({value: org.id, label: org.name, editable: false});
                        current_org_ids.push({value: org.id, label: org.name, editable: false});
                        option_map[org.id] = this_org_ids_options.length - 1;
                    }
                }
                if(check_all_org_setting_access({user_details, selected_ticket, team_info})) {
                    for(let org of all_orgs) {
                        if(option_map[org.id] != undefined) {
                            this_org_ids_options[option_map[org.id]].editable = true;
                            current_org_ids[option_map[org.id]].editable = true;
                        } else {
                            this_org_ids_options.push({value: org.id, label: org.name, editable: true});
                        }
                    }
                } else {
                    let all_editable_store_ids = ['1504672400687'];
                    for(let org of all_orgs) {
                        if(org.customer_success_executive_id == user_details.id || all_editable_store_ids.includes(org.id)) {
                            if(option_map[org.id] != undefined) {
                                this_org_ids_options[option_map[org.id]].editable = true;
                                current_org_ids[option_map[org.id]].editable = true;
                            } else {
                                this_org_ids_options.push({value: org.id, label: org.name, editable: true});
                            }
                        }
                    }
                }
                set_org_ids_options(this_org_ids_options);
                set_org_ids(order_org_id_options(current_org_ids));
            }
			set_priority(selected_ticket.priority);
			// set_customer_priority(selected_ticket.customer_priority);
			set_description(selected_ticket.description);
			set_status(selected_ticket.status);
			set_client_notification(selected_ticket.client_notification);
			set_auto_close(selected_ticket.auto_close)
			if(selected_ticket.design_branch_id)set_design_link('https://design.infurnia.com/design/' + selected_ticket.design_branch_id)
			set_existing_files(selected_ticket.files)
            get_comments(selected_ticket.id)
		}
	}, [selected_ticket, all_orgs]);

	useEffect(() => {
		if(open && selected_ticket_id) get_customer_ticket_by_id(selected_ticket_id)
	}, [open, selected_ticket_id]);

	useEffect(() => {
		if(set_view_mode_true) {
            set_view_mode(true)
            return
        }
        let edit_access = check_ticket_edit_access({user_details, selected_ticket, team_info});
        if(edit_access) {
            set_view_mode(false)
        } else {
            set_view_mode(true)
        }
	}, [set_view_mode_true, user_details, selected_ticket, team_info]);

	const AssignedTo = () => (
		<div className="mb-4" style={{ position: 'relative', zIndex: '5' }}>
			<div className='mb-2 bold'>Product Manager</div>
			<Select
				// closeMenuOnSelect={false}
				// components={animatedComponents}
				value={{ value: assignee_id, label: cs_org_users && cs_org_users.length && assignee_id ? cs_org_users.find(o => o.id === assignee_id).first_name : '' }}
				// isMulti
				options={get_assignee_list()}
				styles={colourStyles4}
				onChange={(selectedOptions) => set_assignee_id(selectedOptions.value)}
				isDisabled={view_mode}
			/>
		</div>
	)

	const Owner = () => (
		<div className="mb-4" style={{ position: 'relative', zIndex: '4' }}>
			<div className='mb-2 bold'>Owner</div>
			<Select
				// closeMenuOnSelect={false}
				// components={animatedComponents}
				value={{ value: creator_id, label: cs_org_users && cs_org_users.length && creator_id ? cs_org_users.find(o => o.id === creator_id).first_name : '' }}
				// isMulti
				options={cs_org_users ? cs_org_users.map(o => { return { value: o.id, label: o.first_name } }) : []}
				styles={colourStyles4}
				onChange={(selectedOptions) => set_creator_id(selectedOptions.value)}
				isDisabled={view_mode}
			/>
		</div>
	)

	const CommentsTab = () => (
		<>
			<div className="mb-3 bold">Comments <Tooltip title='Refresh comments'><AntdButton className="ml-1 px-2 py-1"><span className="cp" onClick={() => { console.log('refreshhhh'); get_comments() }} style={{ opacity: '0.5' }}><i className="fa fa-refresh" /></span></AntdButton></Tooltip></div>
			<div className="mb-4" style={{ display: 'flex' }}>
				<div className="avatar_div mr-2">{user_details && user_details.first_name ? user_details.first_name[0] : ':)'}</div>
				<div style={{ width: "100%" }}>
					<textarea value={new_comment} onChange={(e) => set_new_comment(e.target.value)} style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1' placeholder="Add a comment..." />
					{
						new_comment ?
							<div>
								<AntdButton onClick={() => set_new_comment('')} className="mr-2">Cancel</AntdButton>
								<AntdButton onClick={add_comment} type="primary">Save</AntdButton>
							</div> : ""
					}
				</div>
			</div>
		{
			bo_ticket_comments && bo_ticket_comments.length ? bo_ticket_comments.map((single_comment, idx) => (
				<div className="mb-4" style={{ display: 'flex' }}>
					<div className="avatar_div mr-2">{single_comment.commented_by && cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id === single_comment.commented_by).first_name[0] : ':)'}</div>
					<div style={{ width: "100%" }}>
						<div className="flex_property mb-2">
							<div className="mr-2" style={{ color: '#44546f', fontWeight: 500 }}>{single_comment.commented_by && cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id === single_comment.commented_by).first_name : 'Anonymous User'}</div>
							<div style={{ opacity: '0.5' }}>{timeSince(single_comment.updated_at)}</div>
						</div>
						<div>{single_comment.comment}</div>
						{user_details && single_comment.commented_by === user_details.id ?
							<div>
	
							</div> : ''
						}
					</div>
				</div>
			)):''
		}
		</>
	)

	const TicketDetails = () => (
		<>
            <Row>
                <Col md={8}>
                    <div className='mb-2 bold'>
                        Title*
                        {
                            (selected_ticket && selected_ticket.cs_team_doubts_thread ? <a style={{display: 'inline-block', marginLeft: '10px', verticalAlign: 'bottom'}} href={selected_ticket ? selected_ticket.cs_team_doubts_thread.replace(/spaces\/([^/]+)\/threads\/([^/]+)/, "https://chat.google.com/room/$1/$2/$2") : ''} target="_blank"><i className="fa fa-external-link"></i></a> : '')
                        }
                    </div>
                    <Input readOnly={view_mode} type='text' style={{ height: '36px', color: '#525f7f'  }} className='px-2 py-1 mb-4' placeholder={'Type'} value={title} onChange={(e) => set_title(e.target.value)} />
                </Col>
                    <Col md={4}>
                        <div className='mb-2 bold'>Impact Area*</div>
                        <div style={{ zIndex: 999999999, position: 'relative' }}>
                            <Select
                                value={{ value: impact_area, label: impact_area }}
                                options={impact_area_options}
                                onChange={(selectedOptions) => set_impact_area(selectedOptions.value)}
                                isDisabled={view_mode}
                            />
                        </div>
                    </Col>
            </Row>

			<div className='mb-2 bold'>Org</div>
			<div className="mb-4">
				{
					current_org ?
						<Input type='text' style={{ height: '36px' }} className='px-2 py-1' value={current_org.name} readOnly /> :
                        <Select
                            // closeMenuOnSelect={false}
                            // components={animatedComponents}
                            value={org_ids}
                            isMulti
                            styles={org_id_option_style}
                            options={org_ids_options}
                            isClearable={org_ids.some((v) => v.editable)}
                            onChange={(selectedOptions, actionMeta) => {
                                switch (actionMeta.action) {
                                    case 'remove-value':
                                    case 'pop-value':
                                        if (!actionMeta.removedValue.editable) {
                                            return;
                                        }
                                        break;
                                    case 'clear':
                                        selectedOptions = org_ids_options.filter((v) => !v.editable);
                                        break;
                                }
                                set_org_ids(order_org_id_options(selectedOptions))
                            }}
                        />
				}
			</div>
            {
                // <>
                //     <div className='mb-4 bold'>Customer priority or Client misery level (1 is highest priority, 5 is lowest)</div>
                //     <div className="flex_property mb-4" style={{ fontWeight: 400 }}>
                //         <div>
                //             <div style={{ minHeight: '32px' }}></div>
                //             {/* <div style={{ minHeight: '32px', fontSize: '12px' }}>High priority for customer</div> */}
                //         </div>
                //         {[1, 2, 3, 4, 5].map(x => (
                //             <div>
                //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2">{x}</div>
                //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2" onClick={() => set_customer_priority(x)} >
                //                     <input disabled={view_mode} type="radio" checked={customer_priority === x ? true : false} />
                //                 </div>
                //             </div>
                //         ))}
                //         <div>
                //             <div style={{ minHeight: '32px' }}></div>
                //             {/* <div style={{ minHeight: '32px', fontSize: '12px' }}>Low priority for customer</div> */}
                //         </div>
                //     </div>
                // </>
            }

			<div className='mb-2 bold'>Description</div>
			<textarea readOnly={view_mode} style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7', color: '#525f7f' }} className='px-2 py-1 mb-4' value={description} onChange={(e) => set_description(e.target.value)} placeholder='e.g. Cutlist and preferences set up' />
			{
				// selected_ticket && selected_ticket.back_office_ticket && selected_ticket.back_office_ticket.type === 'bug'?
				// <>
				// 	<div className='mb-2 bold'>Design Link</div>
				// 	<div className="flex_property mb-4" style={{ width: '100%' }}>
				// 		<Input readOnly={view_mode} type='text' style={{ height: '36px', width: '60%' }} className='px-2 py-1 mr-2' placeholder={'Type...'} value={design_link} onChange={(e) => set_design_link(e.target.value)} />
				// 		{/* {org_id && !view_mode ? <Button disabled={view_mode} color='secondary'>Add "pm1" to the Org</Button> : ''} */}
				// 	</div>
				// </>:""
			}
            <Row>
                <Col>
                    <label className="form-control-label mb-4 flex_property" htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
                        <div className='mr-2'>Type</div>
                        <div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('feature') }} >
                            <input disabled={view_mode} type="radio" className="mr-2" checked={type === 'feature' ? true : false} />
                            <div className="mr-4">Feature</div>
                        </div>
                        <div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" onClick={() => { set_type('bug') }} >
                            <input disabled={view_mode} type="radio" className="mr-2" checked={type === 'bug' ? true : false} />
                            <div>Bug</div>
                        </div>
                    </label>
                </Col>
                <Col>
                        <div className="flex_property mr-1 mt-1 mb-4" style={{flexBasis: '15%', opacity:'0.5'}}>
                            Created - {selected_ticket ? date_string(selected_ticket.created_at) : ''}
                        </div>
                </Col>
            </Row>
            {
                // <>
                //     <div className='mb-2 bold'>Priority. 1 is highest priority, 5 is lowest</div>
                //     <div className="flex_property mb-4" style={{ fontWeight: 400 }}>
                //         {[1, 2, 3, 4, 5].map(x => (
                //             <div>
                //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2">{x}</div>
                //                 <div style={{ minHeight: '32px' }} className="ml-2 mr-2" onClick={() => set_priority(x)} >
                //                     <input disabled={view_mode} type="radio" checked={priority === x ? true : false} />
                //                 </div>
                //             </div>
                //         ))}
                //     </div>
                // </>
            }
            {
                /* (view_mode || user_details && user_details.role && user_details.role.store && (user_details.role.store.includes('infurnia_product_manager') || user_details.role.store.includes('customer_success_admin'))) ?
                    AssignedTo() : '' */
            }
            {
                <Row>
                    <Col md={6}>
                        {
                            (view_mode || user_details && user_details.role && user_details.role.store && (user_details.role.store.includes('infurnia_product_manager') || user_details.role.store.includes('customer_success_admin'))) ?
                                AssignedTo() : ''
                        }
                    </Col>
                        <Col md={6}>
                        {
                            Owner()
                        }
                        </Col>
                </Row>

            }
            {
                <div className="mb-4">
                    <div className='mb-2 bold'>Priority (5 fire is highest priority)</div>
                    <Rate className='priority_rating' disabled={!check_ticket_edit_access({user_details, selected_ticket, team_info})} onChange={(value) => set_priority(value ? value : 0)} value={priority ? priority : 0} character={({index = 0}) => <FireFilled/>} />
                </div>
            }
			<div className='mb-2 bold'>Status</div>
			<div className="flex_property mb-4" style={{ width: '100%' }}>
				<div style={{ flex: 3, position: 'relative', zIndex: 1 }}>
					<Select
						// closeMenuOnSelect={false}
						// components={animatedComponents}
						value={{ value:status, label:customer_tickets_status_options.find(o => o.value===status)?customer_tickets_status_options.find(o => o.value===status).label:'' }}
						// isMulti
                        // styles={}
						options={customer_tickets_status_options}
						onChange={(selectedOptions) => {set_status(selectedOptions.value)}}
						isDisabled={view_mode}
					/>
				</div>
				{
					selected_ticket && selected_ticket.customer_id?
					<div className="ml-4 flex_property" style={{ flex: 2 }}>
						<div className="mr-1">Client Notification</div> <Switch disabled={view_mode} size="small" checked={client_notification} onChange={() => set_client_notification(!client_notification)} />
					</div>:''
				}
			</div>
            {
                /* (view_mode || user_details && user_details.role && user_details.role.store && (user_details.role.store.includes('infurnia_product_manager') || user_details.role.store.includes('customer_success_admin'))) ?
                    <>
                        <div className='mb-2 bold'>Expected Resolution Time (Days)</div>
                        <div className="flex_property mb-4" style={{ width: '60%', }}>
                            <Input type='number' readOnly={view_mode} value={expected_resolution_time} onChange={(e) => set_expected_resolution_time(e.target.value)} />
                        </div>
                    </>: '' */
            }
			{<Button color="primary" onClick={update_ticket_details}>Update Ticket Details</Button>}
		</>
	)

	const JiraTab = () => (
			(
				selected_ticket && selected_ticket.jira_key && !show_jira_creation_flow && !show_jira_linking_flow?
				<>
					<div className="flex_property mb-3">
						<div className="mr-2">Jira Issue Link:</div>
						<a href={get_jira_link(selected_ticket.jira_key)} target="_blank" className="mr-2 flex_property" style={{textDecoration:'underline', color:'#0078ff'}}><img src='/jira.svg' height={'18px'} width={'18px'} className="mr-1"/><div>{selected_ticket.jira_key}</div></a>
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Status:</div>
						<div className="bold">{jira_details ? jira_details.status : ''}</div>
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Sprint:</div>
						<div className="bold">{jira_details && jira_details.sprint && jira_details.sprint != '' ? jira_details.sprint : 'Backlog'}</div>
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Due Date:</div>
                        {
                            jira_details ? <DatePicker 
                                format="YYYY-MM-DD" 
                                disabledDate={disabledDate}
                                onChange={(date, dateString) => update_jira_due_date(jira_details.id, dateString)} 
                                defaultValue={jira_details.due_date ? dayjs(jira_details.due_date, 'YYYY-MM-DD') : null}
                            /> : ''
                        }
                        {
                            // <div className="bold">{jira_details ? jira_details.due_date : ''}</div>
                        }
					</div>
					<div className="mb-3 flex_property">
						<div className="mr-2">Fix Version:</div>
						<div className="bold">{jira_details ? jira_details.fix_version : ''}</div>
					</div>
					<div className="mb-2">Summary</div>
					<AntdInput className="mb-3" value={jira_details ? jira_details.summary : ''} readOnly/>
					<div className="mb-2">Description</div>
					<textarea readOnly style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={jira_details ? jira_details.description : ''}  placeholder='Type...' />
					<AntdButton type="primary" className="mr-2" disabled={view_mode} onClick={() => set_show_jira_linking_flow(true)} >Link another Jira issue</AntdButton>	
					<AntdButton type="primary" danger className="mr-2" disabled={view_mode} onClick={() => submit(delink_jira_issue, ()=>{}, 'This action will delink the jira issue of this Product Ticket', '','')} >Delink Jira issue</AntdButton>	
				</>:
				<>
				{
					show_jira_creation_flow?
					<div>
						<div className="mb-2">Summary</div>
						<AntdInput className="mb-4" value={jira_summary} onChange={(e) => set_jira_summary(e.target.value)}/>
						<div className="mb-2">Description</div>
						<textarea style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={jira_description} onChange={(e) => set_jira_description(e.target.value)} placeholder='Type...' />
						<div className="mb-2">Project</div>
                        <div style={{zIndex: '100004'}}><Select styles={{menuPortal: (styles) => ({...styles, zIndex: '100004 !important'})}} className="zIndex_100004 mb-4" options={jira_project_options} value={jira_project} onChange={(value) => set_jira_project(value)}/></div>
						<div className="mb-2">Issue Type</div>
                        <div style={{zIndex: '100003'}}><Select styles={{menuPortal: (styles) => ({...styles, zIndex: '100003 !important'})}} className="zIndex_100003 mb-4" options={jira_type_options} value={jira_type} onChange={(value) => set_jira_type(value)}/></div>
						<div className="mb-2">Assignee</div>
                        <div style={{zIndex: '100002'}}><Select styles={{menuPortal: (styles) => ({...styles, zIndex: '100002 !important'})}} className="zIndex_100002 mb-4" options={jira_assignee_options} value={jira_assignee} onChange={(value) => set_jira_assignee(value)}/></div>
						<div className="flex_property">
							<AntdButton type="primary" className="mr-2" onClick={create_jira} >Submit</AntdButton>	
							<div className="mr-2">or</div>
							<AntdButton type="primary" onClick={() => {set_show_jira_creation_flow(false); set_show_jira_linking_flow(true)}} >Link Existing Issue</AntdButton>	
						</div>
					</div>:(
						show_jira_linking_flow?
						<div>
							<div className="mb-2">Jira Issue Key</div>
							<AntdInput className="mb-4" value={jira_key} onChange={(e) => set_jira_key(e.target.value)}/>
							<div className="flex_property">
								<AntdButton type="primary" className="mr-2" onClick={link_existing_jira} >Link this Issue</AntdButton>	
								<div className="mr-2">or</div>
								<AntdButton type="primary" onClick={() => {set_show_jira_creation_flow(true); set_show_jira_linking_flow(false)}} >Create New Issue</AntdButton>	
							</div>
						</div>:
						<div className="flex_center mt-4" style={{}}>
							<AntdButton type="primary" icon={<PlusCircleOutlined style={{fontWeight:700}} />} disabled={view_mode} onClick={() => set_show_jira_creation_flow(true)}>Create Jira Issue</AntdButton>
							{'\xa0\xa0\xa0or\xa0\xa0\xa0'}
							<AntdButton type="primary" icon={<PlusCircleOutlined style={{fontWeight:700}} />} disabled={view_mode} onClick={() => set_show_jira_linking_flow(true)}>Link Existing Issue</AntdButton>
						</div>
					)
				}
				{
					selected_ticket && selected_ticket.jira_key?
					<AntdButton className="mt-4" type="dashed" onClick={() => {set_show_jira_creation_flow(false); set_show_jira_linking_flow(false)}} icon={<ArrowLeftOutlined style={{fontWeight:700}}/>}>Go Back</AntdButton>:""
				}
				</>
			)
		)

	const props = {
		name: 'file',
		multiple: true,
		action: '',
		customRequest: ({ fileList, onSuccess }) => { onSuccess() },
		defaultFiles: new_files && new_files.length ? new_files.map((x, idx) => { return { uid: x.id, name: ('file_' + (idx + 1) + " (click to view)"), status: 'done'} }) : [],
		onChange(info) {
			const { status } = info.file;
			set_new_files(info.fileList)

			console.log(info.fileList)
			//   if (status !== 'uploading') {
			// 	console.log(info.file, info.fileList);
			//   }
			//   if (status === 'done') {
			// 	message.success(`${info.file.name} file uploaded successfully.`);
			//   } else if (status === 'error') {
			// 	message.error(`${info.file.name} file upload failed.`);
			//   }
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const DriveLinks = () => (
		<div className="flex_center mb-4" style={{flexDirection:'column'}}>
		{
			new_drive_links && new_drive_links.length ? new_drive_links.map((link,idx) => (
				<div className="flex_property" style={{width:'100%'}}>
					<Input key={Math.random()} type='text' style={{ height: '36px' }} className='px-2 py-1 mb-2' placeholder={'Type...'} defaultValue={link} onChange={(e) => {new_drive_links[idx]=e.target.value; console.log('new_drive_links', new_drive_links)}} />
					{
						new_drive_links && new_drive_links.length>1 ?
						<MinusCircleOutlined className="ml-2 cp" onClick={() => set_new_drive_links(new_drive_links.filter((o, index) => index != idx))} />:""
					}
				</div>
			)):''
		}
		<AntdButton type="dashed" className="flex_center" onClick={() => set_new_drive_links([...new_drive_links, ''])}><PlusOutlined /> Add Link</AntdButton>
		</div>
	)

	const FilesTab = useCallback(() => (
		<div>
			{/* <Dragger {...props} style={{ display: 'block' }}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">Click or drag file to this area to upload</p>
				<p className="ant-upload-hint">
					Supports a single or bulk upload.
				</p>
			</Dragger>
			{new_files && new_files.length && !view_mode ? <Button className="mt-4" color='primary' onClick={add_ticket_files}>Update Files</Button> : ''} */}
			<div className="mb-2 bold">Files</div>
			<div className="mb-4">
			{
				existing_files && existing_files.length && existing_files.filter(file => !file.global_path).length ? existing_files.filter(file => !file.global_path).map((file, idx) => (
					<div className="flex_property mb-1">
						<PaperClipOutlined className='mr-2' style={{ opacity: '0.5' }} />
						<a style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:"nowrap"}} href={'https://storage.googleapis.com/' + global.config.ticketing_bucket_name + file.file_path} target="_blank">file{'_' + (idx + 1)} (click to view)</a>
						{view_mode ? '' : <DeleteOutlined className="ml-2 cp" style={{color:'red'}} onClick={() => submit(() => delete_file(file.id), ()=>{}, 'This action will delete the file, are you sure you want to proceed?', '','')} />}
					</div>
				)) : <div style={{opacity:'0.4'}}>No files were uploaded</div>
			}
			</div>
			<div className="mb-2 bold">Links</div>
			<div className="mb-4">
			{
				existing_files && existing_files.length && existing_files.filter(file => file.global_path).length ? existing_files.filter(file => file.global_path).map((file, idx) => (
					<div className="flex_property mb-1">
						<PaperClipOutlined className='mr-2' style={{ opacity: '0.5' }} />
						<a style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:"nowrap"}} href={file.file_path} target="_blank">{file.file_path}</a>
						{view_mode ? '' : <DeleteOutlined className="ml-2 cp" style={{color:'red'}} onClick={() => submit(() => delete_file(file.id), ()=>{}, 'This action will delete the file, are you sure you want to proceed?', '','')} />}
					</div>
				)) : <div style={{opacity:'0.4'}}>No Links were attached</div>
			}
			</div>
			{
				view_mode ? '':
				<>
					<div className="mb-4">
						<div className="mb-2 bold">Add new Files</div>
						<Dragger {...props} fileList={new_files} style={{ display: 'block' }}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">Click or drag file to this area to upload</p>
							<p className="ant-upload-hint">
								Supports a single or bulk upload.
							</p>
						</Dragger>
						{
							new_files && new_files.length ? <AntdButton type="primary" onClick={add_ticket_files}>Submit Files</AntdButton> : ''
						}
					</div>
					<div className="mb-4">
						<div className="mb-2 bold">Add new Links</div>
						{DriveLinks()}
						{
							new_drive_links && new_drive_links.length ? <AntdButton type="primary" onClick={add_drive_links}>Submit Links</AntdButton> : ''
						}
					</div>
                    {
                        selected_ticket && selected_ticket.type != 'bug' ? '' :
                            <div className="mb-4">
                                <div className="mb-2 bold">Checklist</div>
                                {
                                    <div className="flex_center mb-4" style={{flexDirection:'column'}}>
                                        {
                                            Object.keys(ticketChecklist).map((key, idx) => (
                                                <div className="flex_property" style={{width:'100%'}}>
                                                    <Checkbox checked={checklist[key]} onClick={() => {update_checklist_status(key)}}>
                                                        <div>{ticketChecklist[key]}</div>
                                                    </Checkbox>
                                                </div>
                                            ))
                                        }
                                    </div>

                                }
                        </div>
                    }
				</>
			}
		</div>
	), [existing_files, new_files, new_drive_links, selected_ticket, view_mode, checklist])

//	const LinkedBackOfficeTicket = () => (
//		selected_ticket && selected_ticket.back_office_ticket ?
//			<>
//				<Badge.Ribbon text={selected_ticket.back_office_ticket.type === 'bug' ? 'Bug' : (selected_ticket.back_office_ticket.type === 'feature' ? 'Feature' : '')} style={{ fontSize: '12px', display: !['bug', 'feature'].includes(selected_ticket.back_office_ticket.type) ? 'none' : '' }} color={selected_ticket.back_office_ticket.type === 'bug' ? 'red' : (selected_ticket.back_office_ticket.type === 'feature' ? 'green' : '')}>
//					<div className="activity_box mb-4" style={{ padding: '0px' }} >
//						<div style={{ padding: "18px 18px 10px 18px" }}>
//							<div className="flex_between mb-2" style={{ fontSize: '16px', cursor: "pointer" }} onClick={() => select_back_office_ticket(selected_ticket.back_office_ticket.id)}>
//								<div className="flex_property">
//									<i className="fa fa-ticket mr-2" style={{ fontSize: '20px', color: '#A799B7' }} />
//									<div>{selected_ticket.back_office_ticket.title}</div>
//								</div>
//							</div>
//							<div style={{ fontSize: '12px', marginLeft: '28px' }} className="flex_between">
//								<div className="flex_property">
//									{
//										selected_ticket.back_office_ticket.jira_key?
//										<div className="mr-2 flex_property">
//											<a href={`https://infurniacoretech.atlassian.net/jira/software/c/projects/PT/issues/${selected_ticket.back_office_ticket.jira_key}`} target="_blank" className="mr-2 flex_property" style={{textDecoration:'underline', color:'#0078ff'}}><img src='/jira.svg' height={'14px'} width={'14px'} className="mr-1"/><div>{selected_ticket.back_office_ticket.jira_key}</div></a>
//											|
//										</div>:""
//									}
//									{
//										selected_ticket.back_office_ticket.priority ?
//											<div className="mr-2 flex_property">
//												<div className="mr-2">
//													Priority - {selected_ticket.back_office_ticket.priority}
//												</div>
//												|
//											</div> : ""
//									}
//									{
//										/* selected_ticket.back_office_ticket.customer_priority ?
//											<div className="mr-2 flex_property">
//												<div className="mr-2">
//													Customer Priority - {selected_ticket.back_office_ticket.customer_priority}
//												</div>
//												|
//											</div> : "" */
//									}
//									<div className="mr-2" style={{ opacity: '0.5' }}>
//										<i style={{ color: '' }} className="fa fa-history mr-1" />
//										Updated on {date_string(selected_ticket.back_office_ticket.updated_at)}
//									</div>
//								</div>
//
//							</div>
//						</div>
//						<hr className="my-0" />
//						<div className="cp flex_between" style={{ fontSize: '13px', borderRadius: '6px', padding: '6px 10px 6px 18px', }}>
//							<div>
//								<i className="far fa-building mr-1" />{convert_to_readable(selected_ticket.back_office_ticket.department)}
//							</div>
//							{/* <div className="flex_center" style={{color:selected_ticket.back_office_ticket.type==='bug'?'red':(selected_ticket.back_office_ticket.type==='feature'?'green':'')}}>
//										{
//											selected_ticket.back_office_ticket.type==='bug'?
//											<BugOutlined className="mr-1"/>:(
//												selected_ticket.back_office_ticket.type==='feature'?
//												<StarOutlined className="mr-1" />:
//												<RobotOutlined className="mr-1"/>
//											)
//										}
//										{selected_ticket.back_office_ticket.type ? convert_to_readable(selected_ticket.back_office_ticket.type) : 'No type'}
//									</div> */}
//							<div>
//								<i className="far fa-user mr-1" />{cs_org_users && cs_org_users.length && selected_ticket.back_office_ticket.assignee_id ? cs_org_users.find(o => o.id === selected_ticket.back_office_ticket.assignee_id).first_name : 'Not Assigned'}
//							</div>
//							<div className="flex_center" title="Expected resolution time">
//								<i className="far fa-clock mr-1" />{selected_ticket.back_office_ticket.expected_resolution_time ? (selected_ticket.back_office_ticket.expected_resolution_time + ' days') : <NA/>}
//							</div>
//							<div>
//								<Input disabled onClick={(e) => e.stopPropagation()} type='select' style={{ width: '150px', height: '32px', padding: '4px 12px', }}><option>{selected_ticket && selected_ticket.back_office_ticket && bo_tickets_status_options.find(o => o.value===selected_ticket.back_office_ticket.status) ? bo_tickets_status_options.find(o => o.value===selected_ticket.back_office_ticket.status).label : ''}</option></Input>
//							</div>
//						</div>
//					</div>
//				</Badge.Ribbon>
//				{
//					<div>
//						{/* <div className="mb-1 bold">Additional Info:</div> */}
//						{
//							// selected_ticket.back_office_ticket.department === 'product' ?
//							<>
//								{
//									selected_ticket.back_office_ticket.type === 'feature' ?
//										<>
//											<div className='mb-2 bold'>Use Case</div>
//											<textarea readOnly style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={JSON.parse(selected_ticket.back_office_ticket.additional_info).use_case} placeholder='e.g. The client is trying to create shutters with a design that is shown in this image link <url>' />
//										</> : (
//											selected_ticket.back_office_ticket.type === 'bug' ?
//												<>
//													<div className='mb-2 bold'>Reproduction</div>
//													<textarea readOnly style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={JSON.parse(selected_ticket.back_office_ticket.additional_info).reproduction} placeholder='e.g. Add a Cabinet, Change the shutter and external finish and then change shutter design from normal to profile' />
//													{/* <div className='mb-2 bold'>Comments</div>
//													<textarea readOnly style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1 mb-4' value={JSON.parse(selected_ticket.back_office_ticket.additional_info).comments} placeholder='e.g. the same shutter related bug was also noticed at partition level also' /> */}
//												</> : ""
//										)
//								}
//							</> 
//                                /* :
//							<>
//								<label className="form-control-label mt-2 " htmlFor="input-address" style={{ minWidth: '60%', marginBottom: 0 }}>
//									<div className='mr-2 bold'>Global/Org</div>
//									<div className="flex_property mb-4">
//										<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" >
//											<input disabled type="radio" className="mr-2" checked={selected_ticket.back_office_ticket.type === 'org' ? true : false} />
//											<div className="mr-4">Org</div>
//										</div>
//										<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" >
//											<input disabled type="radio" className="mr-2" checked={selected_ticket.back_office_ticket.type === 'global' ? true : false} />
//											<div className="mr-4">Global</div>
//										</div>
//										<div style={{ minHeight: '32px', fontWeight: 400 }} className="ml-2 mr-2 flex_property" >
//											<input disabled type="radio" className="mr-2" checked={selected_ticket.back_office_ticket.type === 'org_and_global' ? true : false} />
//											<div>Both</div>
//										</div>
//									</div>
//								</label>
//							</> */
//						}
//					</div>
//				}
//				<div className="mb-3 bold">Comments <Tooltip title='Refresh comments'><AntdButton className="ml-1 px-2 py-1"><span className="cp" onClick={() => {console.log('refreshhhh'); get_comments()}} style={{opacity:'0.5'}}><i className="fa fa-refresh"/></span></AntdButton></Tooltip></div>
//				<div className="mb-4" style={{display:'flex'}}>
//					<div className="avatar_div mr-2">{user_details && user_details.first_name ? user_details.first_name[0] : ':)'}</div>
//					<div style={{width:"100%"}}>
//						<textarea value={new_comment} onChange={(e) => set_new_comment(e.target.value)} style={{ height: '100px', width: '100%', borderRadius: '6px', border: '1px solid #cad1d7' }} className='px-2 py-1' placeholder="Add a comment..."/>
//						{
//							new_comment?
//							<div>
//								<AntdButton onClick={() => set_new_comment('')} className="mr-2">Cancel</AntdButton>
//								<AntdButton onClick={add_comment} type="primary">Save</AntdButton>
//							</div>:""
//						}
//					</div>
//				</div>
//				{
//					bo_ticket_comments && bo_ticket_comments.length ? bo_ticket_comments.map((single_comment, idx) => (
//						<div className="mb-4" style={{display:'flex'}}>
//							<div className="avatar_div mr-2">{single_comment.commented_by && cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id===single_comment.commented_by).first_name[0] : ':)'}</div>
//							<div style={{width:"100%"}}>
//								<div className="flex_property mb-2">
//									<div className="mr-2" style={{color:'#44546f', fontWeight:500}}>{single_comment.commented_by && cs_org_users && cs_org_users.length ? cs_org_users.find(o => o.id===single_comment.commented_by).first_name : 'Anonymous User'}</div>
//									<div style={{opacity:'0.5'}}>{timeSince(single_comment.updated_at)}</div>
//								</div>
//								<div>{single_comment.comment}</div>
//								{user_details && single_comment.commented_by===user_details.id ? 
//									<div>
//
//									</div>:''
//								}
//							</div>
//						</div>
//					)):''
//				}
//			</> : 'No Linked Product Ticket'
//	)

	const tab_items = [
		{
			key: '1',
			label: 'Details',
			children: TicketDetails(),
		},
		{
			key: '2',
			label: 'Files / Links',
			children: FilesTab(),
		},
		{
			key: '3',
			label: 'Jira Ticket',
			children: JiraTab(),
		},
		{
			key: '4',
			label: 'Comments',
			children: CommentsTab(),
		},
	];

	useEffect(() => {
		console.log('new files', new_files)
	}, [new_files]);

	const callbackTabClicked = (key, event) => {
		set_active_key(key)
		if(key === '3'){
			if(selected_ticket && selected_ticket.jira_key && !jira_details) get_jira_details()
		}
	}

	// const fetch_back_office_tickets = async() => {
	// 	try{
	// 		let user_department 
	// 		if(user_details && user_details.role && user_details.role.store && user_details.role.store.length){
	// 			if(user_details.role.store.includes('customer_success_admin')){
	// 				user_department = 'all'
	// 			}else if(user_details.role.store.includes('cs_rep')){
	// 				user_department = 'customer_success'
	// 			}else if(user_details.role.store.includes('infurnia_product_manager')){
	// 				user_department = 'product'
	// 			}else if(user_details.role.store.includes('infurnia_catalog_admin')){
	// 				user_department = 'catalogue'
	// 			}
	// 		}
	// 		let body = {department: user_department}
	// 		if(link_mode) body['department'] = link_mode_department
	// 		if(!body.department){
	// 			set_initialized(true)
	// 			return
	// 		}
	// 		let resp = await general_fetch({url:'back_office_ticket/get_by_department', body})
	// 		set_back_office_tickets(resp)
	// 		// set_display_back_office_tickets(resp)
	// 		set_initialized(true)
	// 	}catch(err){
	// 		console.error(err)
	// 	}
	// }

	return (
		<Drawer title={(view_mode ? "Viewing" : "Edit") + " Ticket"} onClose={onclick_handleclose} open={open} size="large" bodyStyle={{ paddingTop: '0px' }}>
            {
                // <EditBackOffficeTicket 
                //     open={show_back_office_ticket_drawer}
                //     toggle={() => set_show_back_office_ticket_drawer(!show_back_office_ticket_drawer)}
                //     selected_ticket_id={selected_back_office_ticket}
                //     fetch_back_off_tickets={() => selected_ticket ? [selected_ticket.back_office_ticket] : null}
                //     set_page_loader={set_page_loader}
                //     cs_org_users={cs_org_users}
                //     // all_stores={all_stores}
                //     set_view_mode_true={set_view_mode_true}
                //     user_details={user_details}
                //     update_status={() => 1}
                //     exclude_customer_ticket_drawer={true}
                // />
            }
			<Tabs activeKey={active_key} items={tab_items} onTabClick={callbackTabClicked} />
		</Drawer>
	)
}

export {EditCustomerTicket, check_ticket_edit_access}